import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormType } from '../../../../models/FormTypes';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import RiskProperties from '../../../form-builder/RiskProperties';
import CurrencyDropdown from '../../../shared/CurrencyDropdown';
import Checkbox, { SliderSize } from '../../../shared/form-control/Checkbox';
import ActionEditorBase from '../ActionEditorBase';

const CurrencyInputEditor: FC<EditorProps> = (props) => {
  const { action, patchData, form, patchAction } = props;
  const { data } = action;
  const { currencyCode = 'EUR', allowChange = false } = data || {};

  const { t } = useTranslation('form-builder');

  const onAllowChange = useCallback(
    (value: boolean) => {
      patchData({ allowChange: value });
    },
    [patchData],
  );

  const onCurrencyChange = useCallback(
    (value: string) => {
      patchData({ currencyCode: value });
    },
    [patchData],
  );

  return (
    <div data-cy="currency-input-editor">
      <ActionEditorBase {...props}>
        <div className="mt-4 flex items-center justify-between">
          <div className="text-dpm-12">{t('action-properties.currency-input.symbol')}</div>

          <div className="w-[150px]">
            <CurrencyDropdown selectedCode={currencyCode} onChange={onCurrencyChange} align="right" />
          </div>
        </div>

        <div className="mt-4 flex items-center justify-between">
          <div className="text-dpm-12">{t('action-properties.currency-input.allow-change')}</div>
          <Checkbox slider sliderSize={SliderSize.S} value={allowChange} onChange={onAllowChange} className="my-4" />
        </div>
      </ActionEditorBase>
      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />

      {(form.type === FormType.Asset || form.type == FormType.SubForm || form.type == FormType.SubFormWithApproval) && (
        <RiskProperties action={action} patchAction={patchAction} form={form} />
      )}
    </div>
  );
};

export default CurrencyInputEditor;
